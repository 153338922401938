<template>
  <div>
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
    >
      <span style="font-family:Oswald">Introduction</span>
    </div>
    <div style="margin-top: 10px" />
    <v-layout align-center
              justify-center
              row
              wrap
    >
      <v-flex xs12
              sm8
              md8
              lg8
              xl8
              class="subheader-content font-weight-light"
              :class="{'headline': $vuetify.breakpoint.lgAndUp,'body-2': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
      >
        A matrix is a collection of numbers arranged in a table with a given number
        of rows and columns. Matrices must have the same number of elements in each
        row or column (no jagged edges).
        The following examples are matrices:

        $$
        \mathbf{A} = \begin{bmatrix}
        1 & 10 & -1 \\
        3 & 0 & 34 \\
        1 & 1 & 4 \\
        -2 & 2 & 1.5 \\
        \end{bmatrix}
        \qquad
        \mathbf{B} = \begin{bmatrix}
        1 & -1 \\
        2 & 1 \\
        \end{bmatrix}
        $$

        These are not matices, since they are missing elements in some rows or columns:

        $$
        \mathbf{C} = \begin{bmatrix}
        6 &   \\
        2 & 7 \\
        \end{bmatrix}
        \qquad
        \mathbf{D} = \begin{bmatrix}
        -1 & 2 & 3 \\
        & 5 & 6 \\
        \end{bmatrix}
        $$

        You can perform operations like addition, subtraction, and multiplication
        on matrices much like with regular numbers, but special rules must be followed.
        In this lesson, you will learn how to perform these operations on matrices.
        <br>
        <div style="margin-top: 20px" />
        <div class="font-weight-medium"
             :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Oswald"> Outline</span>
        </div>
        <div style="margin-top: 10px" />
        This chapter on matrices consists of three lessons. Each lesson comes with an interactive MagicGraph that offers students a uniquely engaging and effective learning experience.
        <h5>Lesson 1</h5>
        Matrix Components: How do you talk about the values in a matrix?
        <h5>Lesson 2</h5>
        Matrix Addition: How do you add two matrices together?
        <h5>Lesson 3</h5>
        Matrix Multiplication: When are you allowed to multiply matrices, and how do you do it?
        <br>
        <br>
      </v-flex>
      <v-flex xs12
              sm4
              md4
              lg4
              xl4
      >
        <h5 style="text-align:center">
          Matrices are used in almost every branch of mathematical and scientific discipline. The great theoretical physicist Albert Einstein derived the fundamental equations of Special and General Relativity using matrices.
        </h5>
        <v-img class="imgC"
               padding="0px"
               max-height="500px"
               max-width="500px"
               src="/assets/einstein.jpg"
               contain
        />
      </v-flex>
    </v-layout>
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
    >
      <span style="font-family:Oswald">Let's get started</span>
    </div>
    <div style="margin-top: 10px" />
    <div class="subheader-content font-weight-light"
         :class="{'headline': $vuetify.breakpoint.lgAndUp,'body-2': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
    >
      Explore our comprehensive library of interactive lessons on matrices.
    </div>
    <div style="margin-top: 20px" />
    <v-container grid-list-xl text-xs-center>
      <v-layout
        align-center
        justify-center
        row
      >
        <v-flex v-for="topic in topics"
                :key="topic.key"
                xs12
                sm12
                md12
                lg12
                xl12
        >
          <subtopic-card
            :headline="topic.headline"
            :description="topic.description"
            :img="topic.img"
            :path="topic.path"
          />
        </v-flex>
      </v-layout>
    </v-container>
    <br>
    <br>
    <br>
  </div>
</template>
<script>
import SubtopicCard from '../../../views/SubTopics.vue'
export default {
    name: 'Matrices',
    components: {
    SubtopicCard
    },
    data () {
      return {
        playerOptions: {
          preload:'auto',
          ratio: '16:9'
        },
        topics: [
          {
            headline:    "Matrix Components",
            description: "How do you talk about the values in a matrix?",
            img:         "/assets/wdice-1.svg",
            path:        "/mathematics/matrices/components",
          },
          {
            headline:    "Matrix Addition",
            description: "Learn when you can add two matrices together and practice it!",
            img:         "/assets/wdice-2.svg",
            path:        "/mathematics/matrices/addition",
          },
          {
            headline:    "Matrix Multiplication",
            description: "Matrices can also be multiplied together -- learn how to do it here!",
            img:         "/assets/wdice-3.svg",
            path:        "/mathematics/matrices/multiplication",
          },
          {
            headline:    "Determinant",
            description: "Matrices can also be multiplied together -- learn how to do it here!",
            img:         "/assets/wdice-4.svg",
            path:        "/comingsoon",
          },
        ]
    }
  },
    created () {
      this.$store.commit('navigation/resetState');
      const title = 'Matrices';
      this.$store.commit('navigation/changeTitle', title);
      this.$store.commit('navigation/changeMenu', title);

      // Remap data in our topics section to something that can be used for the
      // side menu. This makes sure there's only one source of data we need to
      // update.
      let newTopics = [];
      for (const topic of this.topics) {
        let newTopic = topic;
        newTopic['action'] = () => this.$router.push({path: topic['path']});
        newTopic['title']  = topic['headline'];
        newTopics.push(newTopic)
      }

      this.$store.commit('navigation/replaceTopics', newTopics);
      let newshowhome = false;
      this.$store.commit('navigation/toggleshowhome', newshowhome);
      let newMath =true;
      this.$store.commit('navigation/replaceMath', newMath);
      let newLeftArrow =true;
      this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
      let newModule=true;
      this.$store.commit('navigation/replaceModule', newModule);
    },
    mounted () {
      MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    },
    metaInfo() {
    return{ title: 'Matrices',
            titleTemplate: '%s - Learn interactively',
            meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                    {name: 'description', content: 'Learn interactively about matrices.'}
                  ]
          }
     }
}
</script>

<style scoped lang="scss">
</style>
